import React, { useEffect, useRef, useState } from 'react'
import Seo from '../components/seo'
import { Link } from 'gatsby'
import Slider from 'react-slick'
import { GoHome } from 'react-icons/go'

import { Immobile } from '../lib/types'
import getTipoImmobile from '../lib/getTipoImmobile'

import Layout from '../components/layout'
import Price from '../components/Price'
import SliderArrow from '../components/SliderArrow'
import ModalSlider from '../components/ModalSlider'
import DetailForm from '../components/DetailForm'
import IconInfo from '../components/IconInfo'
import BuildingInfo from '../components/BuildingInfo'

interface DettagliImmobileProps {
  pageContext: {
    immobile: Immobile
    servizi: { value: string; label: string }[]
  }
}

const DettaglioImmobile: React.FC<DettagliImmobileProps> = ({ pageContext: { immobile, servizi } }) => {
  const [showAllImages, setShowAllImages] = useState<boolean>(false)
  const [firstPhotoIndex, setFirstPhotoIndex] = useState<number>(0)
  const sliderRef = useRef(null)

  useEffect(() => {
    sliderRef.current && sliderRef.current.slickGoTo(firstPhotoIndex)
  }, [])

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderArrow goTo="next" />,
    prevArrow: <SliderArrow goTo="prev" />,
  }

  const numeroLocali = +immobile.datiImmobile.find((dato) => dato.etichetta.toLowerCase() === 'n° locali').valore

  return (
    <Layout servizi={servizi}>
      <>
        <Seo
          title={`${getTipoImmobile(immobile.tipologia, numeroLocali)} a ${immobile.comune}`}
          description={immobile.descrizione}
        />
        <div className="max-w-7xl mx-auto px-6 py-12">
          <div className="flex flex-col lg:flex-row space-y-20 lg:space-y-0 lg:space-x-10">
            <div className="w-full lg:w-2/3">
              {immobile.fotografie && immobile.fotografie.length ? (
                <div className="flex w-full mb-8">
                  <div className="w-2/3 mr-2 border dark:border-gray-700 overflow-hidden">
                    <Slider
                      {...sliderSettings}
                      ref={sliderRef}
                      afterChange={(index: number) => setFirstPhotoIndex(index)}
                    >
                      {immobile.fotografie.map((fotografia, index) => {
                        return (
                          <img
                            key={index}
                            className="object-cover object-center h-96 cursor-pointer"
                            src={`${fotografia.urlFoto}`}
                            alt={`${getTipoImmobile(immobile.tipologia, numeroLocali)} a ${immobile.comune} - cod. ${
                              immobile.codice
                            }`}
                            onClick={() => {
                              setShowAllImages(true)
                              setFirstPhotoIndex(index)
                            }}
                          />
                        )
                      })}
                    </Slider>
                  </div>
                  <div className="w-1/3 flex flex-col justify-between space-y-2">
                    {immobile.fotografie[1] && (
                      <div className="relative flex items-center justify-center h-1/2 border dark:border-gray-700 overflow-hidden">
                        <img
                          className="absolute w-full h-full object-cover cursor-pointer"
                          src={immobile.fotografie[1].urlFoto}
                          alt={`${getTipoImmobile(immobile.tipologia, numeroLocali)} a ${immobile.comune} - cod. ${
                            immobile.codice
                          }`}
                          onClick={() => {
                            setShowAllImages(true)
                            setFirstPhotoIndex(1)
                          }}
                        />
                      </div>
                    )}
                    {immobile.fotografie[2] && (
                      <div className="relative flex items-center justify-center h-1/2 border dark:border-gray-700 overflow-hidden">
                        <img
                          className="absolute w-full h-full object-cover cursor-pointer"
                          src={immobile.fotografie[2].urlFoto}
                          alt={`${getTipoImmobile(immobile.tipologia, numeroLocali)} a ${immobile.comune} - cod. ${
                            immobile.codice
                          }`}
                          onClick={() => {
                            setShowAllImages(true)
                            setFirstPhotoIndex(2)
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : null}

              <h1 className="text-4xl font-semibold mb-1 text-gray-900 dark:text-gray-100">
                {getTipoImmobile(immobile.tipologia, numeroLocali)} a {immobile.comune}{' '}
                <span className="text-xs text-gray-400">cod. {immobile.codice}</span>
              </h1>
              <div className="flex items-center space-x-2 mb-6">
                <Link
                  to={`/immobili/${immobile.comune.toLowerCase()}`}
                  className="text-gray-600 dark:text-gray-400 text-sm hover:underline"
                >
                  {immobile.comune}
                </Link>
                <span className="text-xs text-gray-400 dark:text-gray-600">•</span>
                <Link
                  to={`/immobili/${getTipoImmobile(immobile.tipologia, numeroLocali).toLowerCase()}`}
                  className="text-gray-600 dark:text-gray-400 text-sm hover:underline"
                >
                  {getTipoImmobile(immobile.tipologia, numeroLocali)}
                </Link>
              </div>

              <div className="md:flex md:items-center mb-12">
                <div className="text-3xl font-semibold text-gray-900 dark:text-gray-100">
                  <Price amount={immobile.prezzo} />
                </div>
                <div className="flex items-center mt-2 md:mt-0">
                  {!!immobile.totaleMq && (
                    <div className="md:ml-4 md:pl-4 md:border-l md:border-gray-300 dark:border-gray-600">
                      <IconInfo text="Superficie">
                        {immobile.totaleMq} <small className="font-normal">mq</small>
                      </IconInfo>
                    </div>
                  )}
                  {!!numeroLocali && (
                    <div className="ml-4 pl-4 border-l border-gray-300 dark:border-gray-600">
                      <IconInfo text="Locali">{numeroLocali}</IconInfo>
                    </div>
                  )}
                  {!!immobile.camere && (
                    <div className="ml-4 pl-4 border-l border-gray-300 dark:border-gray-600">
                      <IconInfo text="Camere">{immobile.camere}</IconInfo>
                    </div>
                  )}
                  {!!immobile.bagni && (
                    <div className="ml-4 pl-4 border-l border-gray-300 dark:border-gray-600">
                      <IconInfo text="Bagni">{immobile.bagni}</IconInfo>
                    </div>
                  )}
                </div>
              </div>

              <h2
                className="text-base uppercase tracking-wide
               font-semibold text-blue-900 dark:text-gray-200 leading-none bg-blue-50 dark:bg-gray-700 py-2 px-3 mb-4 rounded"
              >
                Descrizione
              </h2>
              <p className="mb-4 text-gray-900 dark:text-gray-100">{immobile.descrizione}</p>

              <table>
                <BuildingInfo immobile={immobile} type="principale" />
                <BuildingInfo immobile={immobile} type="altre info" />
                <BuildingInfo immobile={immobile} type="extra" />
              </table>
            </div>

            <div className="lg:top-40 lg:sticky lg:self-start sm:w-80 lg:w-1/3 mx-auto">
              <DetailForm immobile={immobile} />
              <div className="mt-8 py-8 px-4 bg-red-700 text-white text-center rounded">
                <GoHome className="text-6xl mx-auto" />
                <p className="mt-4 mb-6">Vuoi vendere la tua casa? Richiedici una valutazione gratuita!</p>
                <Link
                  to="/valuta-il-tuo-immobile"
                  className="block w-full py-2 bg-white text-red-700 font-semibold hover:shadow-lg transition-shadow rounded"
                >
                  Richiedi valutazione
                </Link>
              </div>
            </div>
          </div>

          <ModalSlider
            immobile={immobile}
            showAllImages={showAllImages}
            setShowAllImages={setShowAllImages}
            firstPhotoIndex={firstPhotoIndex}
          />
        </div>
      </>
    </Layout>
  )
}

export default DettaglioImmobile

export async function getStaticPaths() {
  const immobiliRes = await fetch('https://api.immobili.f2.net/v1/immobili?t=7e4aac8c-06e8-4d5f-bf7b-1ba227acadf9')
  const immobili = await immobiliRes.json()

  return {
    paths: immobili.map((immobile) => ({
      params: {
        id: immobile.immobileID,
      },
    })),
    fallback: true,
  }
}

export async function getStaticProps({ params }) {
  const immobileRes = await fetch(
    `http://api.immobili.f2.net/v1/immobili?t=7e4aac8c-06e8-4d5f-bf7b-1ba227acadf9&id=${params.id}`
  )
  const immobile = await immobileRes.json()
  if (immobile.length === 0) {
    return {
      props: {
        immobile: null,
      },
    }
  }

  return {
    props: {
      immobile,
    },
  }
}
