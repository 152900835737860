import React from 'react'
import { Immobile } from '../lib/types'

interface BuildingInfoProps {
  type: string
  immobile: Immobile
}

const BuildingInfo: React.FC<BuildingInfoProps> = ({ immobile, type }) => {
  return (
    <>
      <thead>
        <tr>
          <th colSpan={2}>
            <h3
              className="text-base uppercase tracking-wide
               font-semibold text-blue-900 dark:text-gray-200 leading-none bg-blue-50 dark:bg-gray-700 py-2 px-3 mt-6 mb-4 rounded"
            >
              {type === 'principale' ? 'Dati principali' : type}
            </h3>
          </th>
        </tr>
      </thead>
      <tbody>
        {immobile.datiImmobile
          .filter((dato) => dato.tipologia === type)
          .map((dato) => {
            return (
              <tr key={dato.etichetta}>
                <td className="py-2 pr-4 text-sm uppercase text-gray-600 dark:text-gray-300">{dato.etichetta}</td>
                <td className="py-2 px-4 text-gray-900 dark:text-gray-100">{dato.valore}</td>
              </tr>
            )
          })}
      </tbody>
    </>
  )
}

export default BuildingInfo
