import React, { Fragment, useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import { Dialog, Transition } from '@headlessui/react'
import { MdChevronRight, MdOutlineClose, MdPhotoLibrary } from 'react-icons/md'
import clsx from 'clsx'
import SliderArrow from './SliderArrow'

interface DatoImmobile {
  ordine: number
  etichetta: string
  valore: string
  tipologia: string
}

interface Fotografia {
  urlFoto: string
  ordine: string
  titolo: string
  descrizione: string
}

interface Immobile {
  agenziaID: number
  bagni: string
  camere: string
  cantiereID: null
  cap: string
  categoria: string
  codice: string
  comune: string
  datiImmobile: DatoImmobile[]
  descrizione: string
  descrizioneDE: string
  descrizioneEN: string
  descrizioneES: string
  descrizioneFR: string
  descrizionePT: string
  descrizioneRU: string
  ettari: number
  fotografie: Fotografia[]
  immobileID: string
  isInHomePage: string
  isNuovo: number
  isPrezzoTrattabile: false
  isTrattativaRiservata: false
  latitudine: string
  longitudine: string
  nazione: string
  prezzo: number
  prezzoAPartireDa: string
  provincia: string
  tipologia: string
  totaleMq: string
}

interface ModalSliderProps {
  immobile: Immobile
  showAllImages: boolean
  setShowAllImages: any
  firstPhotoIndex: number
}

const ModalSlider: React.FC<ModalSliderProps> = ({ immobile, showAllImages, setShowAllImages, firstPhotoIndex }) => {
  const [showNavSlider, setShowNavSlider] = useState<boolean>(true)
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const sliderRef1 = useRef(null)
  const sliderRef2 = useRef(null)

  useEffect(() => {
    setNav1(sliderRef1.current)
    setNav2(sliderRef2.current)
  }, [sliderRef1, sliderRef2])

  useEffect(() => {
    nav1 && nav1.slickGoTo(firstPhotoIndex)
    nav2 && nav2.slickGoTo(firstPhotoIndex)
  }, [nav1, nav2, firstPhotoIndex])

  const modalSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderArrow goTo="next" modal />,
    prevArrow: <SliderArrow goTo="prev" modal />,
  }
  const modalNavSliderSettings = {
    className: 'nav-slider',
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    variableWidth: true,
  }

  return (
    <Transition appear show={showAllImages} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-40 overflow-y-auto" onClose={() => setShowAllImages(false)}>
        <div className="h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-70" />
          </Transition.Child>

          <span className="inline-block align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full h-screen overflow-hidden transition-all transform bg-white">
              <div className="flex w-full items-center justify-end py-2 px-8 border-b text-4xl text-gray-500 hover:text-gray-800 transition-colors">
                <button type="button" onClick={() => setShowAllImages(false)}>
                  <MdOutlineClose />
                </button>
              </div>
              <div className="relative">
                <Slider
                  {...modalSliderSettings}
                  asNavFor={nav2}
                  ref={(slider1) => {
                    setNav1(slider1)
                  }}
                >
                  {immobile.fotografie.map((fotografia) => {
                    return (
                      <img
                        key={fotografia.ordine}
                        className={clsx(
                          'block object-contain w-full h-screen transition-all duration-300',
                          showNavSlider ? 'pb-48' : 'pb-12'
                        )}
                        src={fotografia.urlFoto}
                        alt={`${immobile.tipologia} a ${immobile.comune} - cod. ${immobile.codice}`}
                      />
                    )
                  })}
                </Slider>
                <div
                  className={clsx(
                    'absolute bottom-0 left-0 right-0 px-6 py-4 bg-white border-t transition duration-300',
                    showNavSlider ? '-translate-y-12' : 'translate-y-24'
                  )}
                  style={{ marginBottom: showNavSlider ? 0 : 4 }}
                >
                  <button
                    type="button"
                    className="absolute -top-10 -mt-px left-8 flex items-center space-x-2 py-2 px-4 bg-white text-gray-600 rounded-t border-x border-t"
                    onClick={() => setShowNavSlider(!showNavSlider)}
                  >
                    <MdPhotoLibrary />
                    <div>{showNavSlider ? 'Nascondi' : 'Mostra'} anteprime</div>
                    {/* <div>
                      {firstPhotoIndex + 1} / {immobile.fotografie.length}
                    </div> */}
                    <MdChevronRight
                      className={clsx(
                        'text-xl transition-transform duration-300',
                        showNavSlider ? 'rotate-90' : '-rotate-90'
                      )}
                    />
                  </button>
                  <Slider
                    {...modalNavSliderSettings}
                    asNavFor={nav1}
                    ref={(slider2) => {
                      setNav2(slider2)
                    }}
                  >
                    {immobile.fotografie.map((fotografia) => {
                      return (
                        <img
                          key={fotografia.ordine}
                          className="block object-cover object-center aspect-square h-28 p-1 cursor-pointer"
                          src={fotografia.urlFoto}
                          alt={`${immobile.tipologia} a ${immobile.comune} - cod. ${immobile.codice}`}
                        />
                      )
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ModalSlider
