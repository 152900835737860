import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import FormField, { InputType } from './FormField'
import { FormType } from '../templates/valutaImmobile'
import usePostForm from '../api/mutations/usePostForm'
import { Immobile } from '../lib/types'

interface DetailFormProps {
  immobile: Immobile
}

const DetailForm: React.FC<DetailFormProps> = ({ immobile }) => {
  const mutation = usePostForm()
  const [showAllFields, setShowAllFields] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormType>({
    defaultValues: {
      messaggio: '',
      nomeCognome: '',
      email: '',
      telefono: '',
      privacy: false,
      immobile: immobile?.codice,
      tipologiaRichiesta: 'INTERESSATO_A_IMMOBILE',
    },
  })

  const onSubmit = (data) => {
    if (mutation.isSuccess) {
      reset()
    }
    mutation.mutate(data)
  }

  const emailValida = (email: string) => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
  }

  return (
    <>
      {mutation.isSuccess ? (
        <div className="py-4 px-6 rounded bg-lime-50 dark:bg-emerald-800 border border-lime-200 dark:border-emerald-800 text-base text-lime-600 dark:text-gray-200 font-semibold">
          <p className="mb-5">La tua richiesta di informazioni è stata inviata!</p>
          <p>Sarai ricontattato a breve da un nostro consulente.</p>
        </div>
      ) : (
        <div className="w-full px-4 py-6 bg-gray-100 dark:bg-gray-700 border dark:border-transparent rounded">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="mb-4 text-center text-xl font-semibold text-blue-900 dark:text-gray-100">
              Richiedi informazioni
            </h3>
            <div className="mb-3">
              <FormField
                fieldName="messaggio"
                label="Messaggio"
                type={InputType.Textarea}
                validation={{
                  required: 'Inserisci un messaggio',
                }}
                register={register}
                errors={errors}
                onFocus={() => setShowAllFields(true)}
              />
            </div>

            {!!showAllFields && (
              <>
                <div className="mb-3">
                  <FormField
                    fieldName="nomeCognome"
                    label="Nome e cognome"
                    type={InputType.Text}
                    validation={{
                      required: 'Inserisci nome e cognome',
                    }}
                    register={register}
                    errors={errors}
                  />
                </div>

                <div className="mb-3">
                  <FormField
                    fieldName="email"
                    label="Email"
                    type={InputType.Text}
                    validation={{
                      required: "Inserisci un'email",
                      validate: (value: string) => !value || emailValida(value) || "Inserire un'email valida",
                    }}
                    register={register}
                    errors={errors}
                  />
                </div>

                <div className="mb-4">
                  <FormField
                    fieldName="telefono"
                    label="Telefono"
                    type={InputType.Text}
                    register={register}
                    errors={errors}
                  />
                </div>

                <div className="mb-4">
                  <FormField
                    fieldName="privacy"
                    label={() => (
                      <span className="block leading-3 font-normal">
                        Acconsento al trattamento dei miei dati come da{' '}
                        <a href="/informativa-privacy" target="_blank" rel="noopener noreferrer" className="underline">
                          informativa privacy
                        </a>
                      </span>
                    )}
                    type={InputType.Boolean}
                    validation={{
                      required: 'Acconsenti al trattamento dati',
                    }}
                    register={register}
                    errors={errors}
                  />
                </div>
              </>
            )}
            {mutation.isError && (
              <div className="py-2 px-3 rounded bg-red-100 border border-red-300 text-xs font-semibold my-4 text-red-700">
                È avvenuto un errore durante l'invio della richiesta. Ti chiediamo di riprovare o contattarci al n°
                035&nbsp;564&nbsp;533.
              </div>
            )}
            <button
              type="submit"
              className="w-full text-center text-sm uppercase tracking-wider bg-lime-600 hover:bg-lime-700 dark:hover:bg-lime-500 hover:shadow-2xl py-2 px-3 text-white transition-colors rounded"
            >
              Invia richiesta
            </button>
          </form>
        </div>
      )}
    </>
  )
}

export default DetailForm
