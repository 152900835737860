import React from 'react'
import { RiLayout4Line, RiSplitCellsHorizontal } from 'react-icons/ri'
import { MdOutlineBathroom, MdOutlineBedroomParent } from 'react-icons/md'

interface IconInfoProps {
  text: string
}

const IconInfo: React.FC<IconInfoProps> = ({ text, children }) => {
  const getIcon = (type) => {
    switch (type) {
      case 'Superficie':
        return <RiSplitCellsHorizontal />
      case 'Locali':
        return <RiLayout4Line />
      case 'Camere':
        return <MdOutlineBedroomParent />
      case 'Bagni':
        return <MdOutlineBathroom />
      default:
        return null
    }
  }
  return (
    <>
      <div className="flex items-center justify-center space-x-1 text-md">
        <div className="text-gray-400 dark:text-gray-400">{getIcon(text)}</div>
        <div className="font-semibold text-red-700 dark:text-gray-100">{children}</div>
      </div>
      <div className="text-gray-500 dark:text-gray-400 text-xs text-center uppercase">{text}</div>
    </>
  )
}

export default IconInfo
