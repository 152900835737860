import React from 'react'
import clsx from 'clsx'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'

interface SliderArrowProps {
  goTo: 'next' | 'prev'
  modal?: boolean
  style?: any
  onClick?: any
}

const SliderArrow: React.FC<SliderArrowProps> = ({ modal = false, goTo, style, onClick }) => {
  return (
    <div
      style={{ ...style }}
      onClick={onClick}
      className={clsx(
        'absolute top-1/2 -translate-y-1/2 flex items-center justify-center text-4xl z-40 cursor-pointer transition-colors',
        goTo === 'next' ? 'right-2' : 'left-2',
        modal ? '-mt-12 text-gray-500 hover:text-gray-800' : 'text-gray-300 hover:text-white'
      )}
    >
      {goTo === 'next' && <BsChevronRight />}
      {goTo === 'prev' && <BsChevronLeft />}
    </div>
  )
}

export default SliderArrow
